import React from 'react';
import Flag from 'react-world-flags';

import Layout from '../components/layout';
import SEO from '../components/seo';

const CinelanguesPage = () => (
  <Layout>
    <SEO
      title="Cinélangues 2024-2025 - Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/a-propos-cinelangues-2023-2024/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 header-content my-auto ml-2">
            <h1>
              <i className="fal fa-camera-movie ml-2 mr-2"></i>Edito 2024-2025
            </h1>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="row">
          <div className="card card-orange col-lg-6">
            <div className="card-body">
              <h3 className="mt-0">
              Cinélangues poursuit depuis 15 ans, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies espagnoles et hispano-américaines, italienne et allemande, auprès du public scolaire, par le biais de programmations spécifiques enrichies de partenariats avec des festivals.
              </h3>
            </div>
          </div>
          <div className="card card-illustration-3 d-none d-lg-block col-lg-6"></div>
          <div className="card card-content col-lg-12">
            <div className="card-body col-text-2">
              <p className="lead">
                Vous êtes – professeurs de langues, d’histoire, documentalistes – toujours nombreux à nous suivre dans notre démarche et à considérer qu’emmener vos élèves dans une salle de cinéma pour leur faire découvrir ensemble la culture, l’histoire, la société, des pays dont ils apprennent la langue est un élément fondamental de votre enseignement. Nous vous remercions vivement de votre indéfectible soutien!
              </p>
              <p>
                Les programmations Cinélangues 2024-2025 sont, comme toujours, placées sous le signe de la jeunesse et du regard d’enfant: vous y découvrirez des jeunes et des adolescents qui luttent malgré les difficultés qui les entourent pour trouver leur chemin de vie. C’est le cas dans de nouveaux films comme Mis hermanos, Tom und Hacke, Gloria et de films que nous avons déjà programmés tels que Camila sortira ce soir et Un mundo para Julius.
              </p>
              <p>
                De nombreux films mettent en avant l’histoire et la mémoire :  en Allemagne surtout, avec des films comme La belle affaire, qui aborde la réunification monétaire des deux Allemagne, thème jamais traité au cinéma, Fritzi, Le vent de la liberté et La révolution silencieuse, et en Amérique latine, avec Chili 1976.
              </p>
              <p>
                L’Italie nous offre cette année une belle programmation presque totalement renouvelée avec quatre films de réalisatrices qui mettent en avant les femmes. Tous proposent un large choix de portraits de femmes fortes et de figures féminines d’exception : Primadonna s’inspire de l’histoire vraie de la première Sicilienne qui a refusé le mariage « réparateur », Gloria ! nous transporte à Venise, au 18e siècle, à l’Institut Sant'Ignazio, orphelinat et conservatoire pour jeunes filles.. Il reste encore demain, tout en nous plongeant dans la ville de Rome en 1946, aborde la question toujours actuelle des droits des femmes. La Nouvelle femme enfin offre une plongée dans la vie de Maria Montessori à qui nous devons une approche pédagogique révolutionnaire et visionnaire.
              </p>
              <p>
                Nous espérons donc que ces programmations 2024-2025 dans leur diversité répondront à vos attentes et que cette année encore nous aurons le plaisir de vous retrouver nombreux avec vos élèves dans nos salles partenaires.
              </p>
              <p>
                <strong>Odile MONTAUFRAY</strong><br />
                Présidente Co-Fondatrice
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CinelanguesPage;
